import React from "react"
import Image from "../../components/elements/Image"
import Header from "../../components/models/header"
import Layout from "../../components/layout"
import { Title } from "../../styles/typography.styles"
import { RamBlock, Row } from "../../components/home/home.styles";
import {
  Block,
  BlockRow,
  BlockContent,
  BlockImg,
  Line,
  Text,
  RowPhoto,
  RowPhotoCard,
  RelativeLabel,
  BlockTitle,
  BlockText,
} from "../../components/models/models-ram.styles"
import ModelsCard from "../../components/home/models-card"

const Ram = () => {
  return (
    <Layout title={`Ram 1500`}>
      <Header img={"ram_general_banner@2x.jpg"} />

      <main>
        <div className={`container`}>
          <Title>RAM 1500</Title>

          <Row>
            <ModelsCard
              grid={5}
              title="BIGHORN"
              url="/ram/bighorn"
              image="/images/home/ram_bighorn.jpg"
            />
            <ModelsCard
              grid={5}
              title="REBEL"
              url="/ram/rebel"
              image="/images/home/ram_rebel.jpg"
            />
            <ModelsCard
              grid={5}
              title="LARAMIE"
              url="/ram/laramie"
              image="/images/home/ram_laramie.jpg"
            />
            <ModelsCard
              grid={5}
              title="LONGHORN"
              url="/ram/longhorn"
              image="/images/home/ram_longhorn.jpg"
            />
            <ModelsCard
              grid={5}
              title="LIMITED"
              url="/ram/limited"
              image="/images/home/ram_limited.jpg"
            />
          </Row>
        </div>

        <Block black>
          <Title color={"white"}>Z zewnątrz</Title>

          <div className={"container"}>
            <Image fileName={"ram_general_exterior@2x.jpg"} alt={"RAM 1500"}/>
          </div>
        </Block>

        <Block style={{marginTop: "0"}}>
          <div className={"container"}>
            <Title>Wszechstronność</Title>

            <BlockRow>
              <BlockContent>
                <h4 className="title">RAMBOX®</h4>
                <Line />
                <div className="text">
                  Ekskluzywny system zarządzania bagażem RAMBOX® oferuje
                  wszechstronne, odporne na wszelkie warunki atmosferyczne,
                  zamykane, podświetlane i odprowadzające wodę pojemniki po
                  każdej stronie części bagażowej pojazdu.
                </div>
              </BlockContent>
              <BlockImg>
                <Image fileName={"2020-DT-Exterior-Rambox-Desktop-01@2x.jpg"} alt={"RAM 1500"} />
              </BlockImg>
            </BlockRow>

            <Title>Wielofunkcyjna klapa tylna</Title>

            <BlockRow className={`reverse`}>
              <BlockImg>
                <Image
                  fileName={"2020-DT-VLP-Capability-Desktop-01-3@2x.jpg"} alt={"RAM 1500"}
                />
              </BlockImg>
              <BlockContent >
                <h4 className="title">Nieograniczony dostęp</h4>
                <Line />
                <div className="text">
                  Nowa inżynieryjna aranżacja klapy tylnej z podziałem 60/40
                  oferuje wiele sposobów dostępu do ładunku niezależnie od
                  wykonywanego zadania. Funkcyjna i niesamowicie wytrzymała.
                </div>
              </BlockContent>
            </BlockRow>

            <Title>Wiele możliwości</Title>

            <Text>
              Wielofunkcyjna klapa tylna dopasowuje się do twoich potrzeb dzięki
              posiadaniu dzielonych drzwi, które otwierają się na kilka
              sposobów. Można je odchylić w proporcji 60/40, otworzyć do kąta do
              88 ° lub rozłożyć jak tradycyjną klapę tylną.
            </Text>

            <RowPhoto>
              <RowPhotoCard>
                <Image fileName={"DT20-MFTG-Radio-3-closed-1@2x.jpg"} alt={"RAM 1500"} />
                <span>Całkowicie zamknięta</span>
              </RowPhotoCard>
              <RowPhotoCard>
                <Image fileName={"DT20-MFTG-Radio-2-down-1@2x.jpg"} alt={"RAM 1500"} />
                <span>Opuszczona</span>
              </RowPhotoCard>
              <RowPhotoCard>
                <Image fileName={"DT20-MFTG-Radio-1-open-1@2x.jpg"} alt={"RAM 1500"} />
                <span>Na oścież</span>
              </RowPhotoCard>
            </RowPhoto>
          </div>
        </Block>

        <div className={`container`} style={{ position: "relative" }}>
          <Image fileName={"2020-DT-Exterior-Capacity-Desktop_01@2x.jpg"} alt={"RAM 1500"} />

          <RelativeLabel style={{ bottom: "-4rem", left: "1.5rem" }}>
            <h5 className="title">900 kg</h5>
            <Line />
            <div className="text align-right">
              udźwigu
            </div>
          </RelativeLabel>
        </div>

        <Block black style={{ margin: "0 0 12rem" }}>
          <Title color={"white"}>BEZPIECZEŃSTWO</Title>

          <div className={`container`} style={{ position: "relative", marginBottom: "-8rem" }} >
            <Image fileName={"ram_general_safety_01.jpg"} alt={"RAM 1500"} />

            <RelativeLabel style={{ bottom: "-4rem", right: "1.5rem" }}>
              <h5 className="title align-right">
                PONAD 100 FUNKCJI
              </h5>
              <Line align={"flex-end"} />
              <div className="text align-right">
                STANDARDOWYCH I OPCJONALNYCH DLA OCHRONY I BEZPIECZEŃSTWA
              </div>
            </RelativeLabel>
          </div>
        </Block>

        <div className={`container`} style={{ position: "relative" }}>
          <Image fileName={"ram_general_safety_02@2x.jpg"} alt={"RAM 1500"} />

          <RelativeLabel style={{ top: "-4rem", left: "1.5rem" }}>
            <h5 className="title">Inteligentny system holowania</h5>
            <Line />
            <div className="text">
              Dostępny we wszystkich modelach RAM 1500 z 2020 roku. Funkcja
              monitorowania martwego pola z wykrywaniem holowanej przyczepy
              skanuje obszary martwego pola zarówno za pojazdem, jak i obok
              niego, mierząc automatycznie długość przyczepy w celu bezpiecznego
              wykonania manewru.
            </div>
          </RelativeLabel>

          <RowPhoto style={{margin: "4rem 0"}}>
            <RowPhotoCard>
              <Image fileName={"2020-DT-Safety-Technology-Desktop-02-LaneDetection@2x.jpg"} />
              <BlockTitle className={`title`}>Ostrzeżenie przed zjechaniem <br/> z pasa ruchu</BlockTitle>
              <Line/>
              <BlockText>System LaneSense® – Plus zapobiega niezamierzonemu zjechaniu z pasa ruchu zapewniając wizualny sygnał oraz bezpiecznie prowadząc pojazd z powrotem na odpowiednią pozycję.</BlockText>
            </RowPhotoCard>
            <RowPhotoCard>
              <Image fileName={"2020-DT-Safety-Technology-Desktop-03-ForwardWarning@2x.jpg"} alt={"RAM 1500"} />
              <BlockTitle className={`title`}>Inteligentny <br/> tempomat</BlockTitle>
              <Line/>
              <BlockText>Dostępny tempomat adaptacyjny z funkcją STOP&GO wykrywający pojazdy poruszające się z przodu. Ostrzega przed kolizją, a zaawansowany asystent hamowania pomaga automatycznie utrzymać żądaną odległość od poprzedzających pojazdów.</BlockText>
            </RowPhotoCard>
            <RowPhotoCard>
              <Image fileName={"2020-DT-Safety-Technology-Desktop-04-ParkAssist@2x.jpg"} alt={"RAM 1500"} />
              <BlockTitle className={`title`}>Asystent <br/> Parkowania</BlockTitle>
              <Line/>
              <BlockText>Funkcja parkowania równoległego oraz prostopadłego zapewnia aktywne podpowiedzi podczas parkowania. System sugeruje kierowcy włączenie biegu, hamulca i przyspieszenia, podczas gdy sam kontroluje sterowanie pojazdem.</BlockText>
            </RowPhotoCard>
          </RowPhoto>
        </div>

        <div className={`container`} style={{ position: "relative" }}>
          <Image fileName={"ram_general_safety_03@2x.jpg"} alt={"RAM 1500"} />

          <RelativeLabel style={{ top: "-4rem", left: "1.5rem" }}>
            <h5 className="title align-right">ZAAWANSOWANA TECHNOLOGIA PODUSZEK POWIETRZNYCH</h5>
            <Line align={'flex-end'} />
            <div className="text align-right">
              Sześć standardowych poduszek powietrznych zapewnia ochronę i spokój dla wszystkich pasażerów pojazdu. W przypadku zderzenia czołowego, przednie poduszki powietrzne są wyposażone w technologię adaptacyjnego odpowietrzania. Dzięki niej w momencie zderzenia w poduszce otwiera się otwór wentylacyjny, który dostosowuje się do wielkości pasażera i siły wypadku.
            </div>
          </RelativeLabel>
        </div>

        <Title>Stworzony by pomagać i chronić</Title>
        <Text>
          2020 RAM 1500 został zaprojektowany dla twojego bezpieczeństwa. Dzięki wyjątkowej konstrukcji pojazdu, niezależnie od wielkości i wartości przewożonego ładunku możesz to robić z pewnością i spokojem.
        </Text>

        <div className={`container`} style={{ position: "relative", margin: "0 auto 8rem" }}>
          <Image fileName={"ram_general_safety_04@2x.jpg"} alt={"RAM 1500"} />

          <RelativeLabel style={{ bottom: "-4rem", left: "1.5rem" }}>
            <h5 className="title">STAL O WYSOKIEJ <br/> WYTRZYMAŁOŚCI</h5>
            <Line />
            <div className="text">
              98% stali zastosowanej w ramie to stal o wysokiej wytrzymałości, dzięki czemu Ram 1500 jest wyjątkowo wytrzymały.
            </div>
          </RelativeLabel>
        </div>

        <div className={`container`} style={{ position: "relative" }}>
          <Image fileName={"ram_general_safety_05@2x.jpg"} alt={"RAM 1500"} />

          <RelativeLabel style={{ top: "-4rem", right: "1.5rem" }}>
            <h5 className="title align-right">Oczy <br/> dookoła głowy </h5>
            <Line align={'flex-end'} />
            <div className="text align-right">
              Kamera 360° zapewnia przejrzysty obraz otoczenia pojazdu. Urządzenie wizualne sprawia, że parkowanie, załadunek i rozładunek oraz podłączenia przyczepy są bezpieczne i łatwe.
            </div>
          </RelativeLabel>
        </div>

        <Block black style={{marginBottom: "10rem"}}>
          <Title color={"white"}>WNĘTRZE</Title>

          <div className={"container"} style={{ marginBottom: "-8rem"}}>
            <Image fileName={"ram_general_interior_01@2x.jpg"} alt={"RAM 1500"} />
          </div>
        </Block>

        <Title>NAJBARDZIEJ LUKSUSOWY W SWOJEJ KLASIE</Title>

        <div className={`container`} style={{ position: "relative" }}>
          <Image fileName={"ram_general_interior_02@2x.jpg"} />

          <RelativeLabel style={{ bottom: "0", right: "1.5rem" }}>
            <h5 className="title align-right">INTEGRACJA <br/> ZE SMARTFONEM</h5>
            <Line align={"flex-end"} />
            <div className="text align-right">
              Dostępny we wszystkich modelach RAM 1500 z 2020 roku. Funkcja
              monitorowania martwego pola z wykrywaniem holowanej przyczepy
              skanuje obszary martwego pola zarówno za pojazdem, jak i obok
              niego, mierząc automatycznie długość przyczepy w celu bezpiecznego
              wykonania manewru.
            </div>
          </RelativeLabel>
        </div>
        <div className={`container`}>
          <RowPhoto style={{margin: "4rem 0"}}>
            <RowPhotoCard>
              <Image fileName={"2020-DT-Interior-ConsoleFeatures-Desktop-01@2x.jpg"} alt={"RAM 1500"} />
              <BlockTitle className={`title`}>Cyfrowy <br/> wyświetlacz</BlockTitle>
              <Line/>
              <BlockText>Dostępny 7-calowy cyfrowy wyświetlacz informacyjny klasy premium ożywia informacje dzięki kolorowej grafice 3D.</BlockText>
            </RowPhotoCard>
            <RowPhotoCard>
              <Image fileName={"2020-DT-Interior-ConsoleFeatures-Desktop-02@2x.jpg"} alt={"RAM 1500"} />
              <BlockTitle className={`title`}>Aktywna <br/>redukcja hałasu</BlockTitle>
              <Line/>
              <BlockText>Montowane na samochodzie i odpowiednio dostrojone moduły masy działają w harmonii z dostępnym wewnątrz systemem eliminacji hałasu. Pomaga on redukować dźwięk z otoczenia, zapewniając wewnątrz pojazdu spokój na poziomie 57,6 decybeli.</BlockText>
            </RowPhotoCard>
            <RowPhotoCard>
              <Image fileName={"2020-DT-Interior-ConsoleFeatures-Desktop-02@2x.jpg"} alt={"RAM 1500"} />
              <BlockTitle className={`title`}>Łączność <br/>w trasie</BlockTitle>
              <Line/>
              <BlockText>Nikt nie lubi rozładowanej baterii w swoim urządzeniu przenośnym, dlatego RAM wychodzi temu naprzeciw. Pozostań naładowany i połączony. Centrum multimedialne z maksymalnie czterema ultraszybkimi portami ładującymi USB i trzema gniazdami 115 woltów oznacza, że zawsze będziesz miał zasilanie.</BlockText>
            </RowPhotoCard>
          </RowPhoto>
        </div>

        <div className={`container`} style={{ position: "relative" }}>
          <Image fileName={"ram_general_interior_03@2x.jpg"} alt={"RAM 1500"} />

          <RelativeLabel style={{ top: "-4rem", left: "1.5rem" }}>
            <h5 className="title">HARMAN KARDON® <br/> PREMIUM AUDIO</h5>
            <Line />
            <div className="text">
              Uzyskaj muzykę, która cię poruszy. 2020 Ram 1500 może pochwalić się 900-watowym systemem audio Harman Kardon® Premium. Ten wyjątkowy system obejmuje 19 głośników - najwięcej w swojej klasie - i jest dostępny z oryginalnymi kratkami głośnikowymi ze stali nierdzewnej (dostępnymi w modelach Laramie®, Rebel, Longhorn® i Limited Crew Cab).
            </div>
          </RelativeLabel>
        </div>

        <div className="container">
          <Title>Pojemny i przestronny</Title>
          <Text>
            Ty i twoi znajomi możecie w pełni cieszyć się jazdą dzięki wielu opcjom ustawienia konsoli i sprytnym schowkom.
          </Text>

          <RowPhoto>
            <RowPhotoCard grid={4}>
              <Image fileName={"2020-DT-Interior-Comfort-Desktop-01@2x.jpg"} alt={"RAM 1500"} />
              <span>Tylna przestrzeń na nogi 1.155 - 45.1 cali</span>
            </RowPhotoCard>
            <RowPhotoCard grid={4}>
              <Image fileName={"2020-DT-Interior-Comfort-Desktop-02@2x.jpg"} alt={"RAM 1500"} />
              <span>Dostępne rozkładane tylne siedzenia</span>
            </RowPhotoCard>
            <RowPhotoCard grid={4}>
              <Image fileName={"2020-DT-Interior-Comfort-Desktop-03-1@2x.jpg"} alt={"RAM 1500"} />
              <span>Składana konsola środkowa tylnego siedzenia</span>
            </RowPhotoCard>
            <RowPhotoCard grid={4}>
              <Image fileName={"2020-DT-Interior-Comfort-Desktop-04@2x.jpg"} alt={"RAM 1500"} />
              <span>Schowek pod tylną kanapą</span>
            </RowPhotoCard>
          </RowPhoto>
        </div>

        <div className={`container`} style={{ position: "relative", marginBottom: '8rem' }}>
          <Image fileName={"ram_general_interior_04@2x.jpg"} alt={"RAM 1500"} />

          <RelativeLabel style={{ bottom: "-4rem", right: "1.5rem" }}>
            <h5 className="title align-right">Podwójny <br/> panoramiczny dach</h5>
            <Line align={'flex-end'} />
            <div className="text align-right">
              Panoramiczny dach oferuje pasażerom niebywałe emocje, otwarte niebo i niezrównany widok na otaczający świat.
            </div>
          </RelativeLabel>
        </div>

        <RamBlock>
          <div className={`container`}>
            <Title color={'white'}>RAM 1500</Title>

            <Row black>
              <ModelsCard black grid={5}
                          title="BIGHORN"
                          url="/ram/bighorn"
                          image="/images/home/ram_bighorn.jpg"
                          color="white"/>
              <ModelsCard black grid={5}
                          title="REBEL"
                          url="/ram/rebel"
                          image="/images/home/ram_rebel.jpg"
                          color="white"/>
              <ModelsCard black grid={5}
                          title="LARAMIE"
                          url="/ram/laramie"
                          image="/images/home/ram_laramie.jpg"
                          color="white"/>
              <ModelsCard black grid={5}
                          title="LONGHORN"
                          url="/ram/longhorn"
                          image="/images/home/ram_longhorn.jpg"
                          color="white"/>
              <ModelsCard black grid={5}
                          title="LIMITED"
                          url="/ram/limited"
                          image="/images/home/ram_limited.jpg"
                          color="white"/>
            </Row>
          </div>
        </RamBlock>

      </main>
    </Layout>
  )
}

export default Ram
