import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const Block = styled.div`
  width: 100%;
  background-color: ${props => props.black ? '#131415' : 'white'} ;
  padding-top: 2px;
  padding-bottom: 4rem;
  margin-top: 7rem;
`;

export const BlockRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  
  ${breakpoints('tabPort') `
    flex-direction: column;
    align-items: center;
    
    &.reverse {
      flex-direction: column-reverse;
  `} 
`;

export const BlockContent = styled.div`
  width: 36%;
  
  ${breakpoints('tabPort') `
    width: 100%;
    margin-bottom: 4rem;
  `} 
  
  .title {
    color: black;
    font-family: ${props => props.theme.fonts.heading};
    font-size: 2.4rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    
    ${breakpoints('tabPort') `
      font-size: 2rem;
    `} 
  }
  
  .text {
    color: ${props => props.theme.colors.text};
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`;

export const BlockTitle = styled.div`
  color: black;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.4rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  
  ${breakpoints('phone') `
    text-align: center;
  `}
`;

export const BlockText = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.4rem;
  line-height: 2.4rem;
  
  ${breakpoints('phone') `
    text-align: center;
  `}
`;

export const Line = styled.div`
  width: 10rem;
  height: 1px;
  background-color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  align-self: ${props => props.align ? props.align : 'flex-start'};
  
  ${breakpoints('phone') `
    align-self: center;
  `}
`;

export const BlockImg = styled.div`
  width: 60%;
  
  ${breakpoints('tabPort') `
    width: 100%;
  `}
  
  img {
    width: 100%;
  }
`;

export const Text = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  margin: 0 auto 4rem;
  max-width: 92rem;
`;

export const RowPhoto = styled.div`
  display: flex;
  justify-content: space-between;
  
  ${breakpoints('tabPort') `
    flex-direction: column;
    align-items: center;
  `} 
`;

export const RowPhotoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.grid === 4 ? '24%' : '32%'};
  margin-bottom: 4rem;
  
  ${breakpoints('tabPort') `
    width: 100%;
  `} 
  
  .gatsby-image-wrapper {
    width: 100%;
  }
  
  .title {
    width: 100%;
    margin-top: 2rem;
    font-weight: 500;
    
    ${breakpoints('phone') `
      text-align: center;
    `}
  }
  
  span {
    display: inline-block;
    color: ${props => props.theme.colors.text};
    font-size: 1.4rem;
    text-align: center;
    margin-top: 2rem;
  }
`;

export const BlockRelative = styled.div`
  position: relative;
`;

export const RelativeLabel = styled.div`
  position: absolute;
  z-index: 10;
  background-color: black;
  padding: 2rem 4rem;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  
  ${breakpoints('tabPort') `
    padding: 1rem 2rem;
  `} 
  
  ${breakpoints('phone') `
    position: unset; 
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
  `} 
  
  .title {
    color: white;
    font-family: ${props => props.theme.fonts.heading};
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    
    ${breakpoints('tabPort') `
      font-size: 2rem;
    `} 
    
    ${breakpoints('phone') `
      text-align: center;
    `}
  }
  
  .text {
    color: #A7A7A7;
    font-size: 1.4rem;
    line-height: 2.4rem;
    
    ${breakpoints('phone') `
      text-align: center;
    `}
  }
  
  .align-right {
    text-align: right;
    
    ${breakpoints('phone') `
      text-align: center;
    `}
  }
`;

export const GalleryThumb = styled.div`
  position: relative;
  width: 100%;
  
  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
  }
`;
